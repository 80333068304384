import _ from 'lodash'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import actions, {
  SAGA_LOCALE_CHANGE,
  SAGA_INIT_APP,
  SAGA_POWERFORM_SUBMIT
} from './actions'
import * as api from './lib/api'
import { validateRecipients } from './lib/validate'
import { getErrorMessageName } from './lib/error_parser'
import { setTranslations } from './lib/translate'
import { languagesMapping } from './lib/lang'
import * as mixpanel from './analytics/mixpanel'
import queryString from 'query-string'
//import { getHdnPersistOriginalValues } from './reducers/context'
import { uuid } from '@ds/sidekick';
import { getQueryParam } from './lib/getQueryParam'

export function* handleLanguageChange({ payload }) {
  try {
    document.cookie = `DocuSignLanguage=${payload.langCode};`
    yield put(actions.setLocale(payload.langCode))
    window.location.reload()
  } catch (error) {
    window.location.href = `?lang=${payload.langCode}`
  }
}

const redirect = (url) => {
  if (url) {
    global.location.assign(url)
  }
}

/**
 * The regex grabs the query string given the key and returns the value of the
 * key from the current url
 */
export const getQueryStringValue = key => {
  const queryParams = window.location.search.replace("amp;", "").replace("amp%3B", "").slice(1).split('&')
    .reduce((accumulator, value) => {
      const [k, v] = value.split('=');
      try {
        accumulator[k] = decodeURIComponent(v);
      } catch (e) {
        accumulator[k] = v;
      }
      return accumulator
    }, {})
  return queryParams[key]
}

const removeQueryStringValue = (url, values) => {
  const query = queryString.parse(url)
  const result = _.omitBy(query, (value, key) =>  _.some(values, value => _.includes(key, value)))
  return (Object.getOwnPropertyNames(result).length === 0) ? '' : `?${queryString.stringify(result)}`
}

export const ERROR = 'danger'
export const INFO = 'information'

export function* handleInit({
  payload: { params, query, hdnStartValues, hdnPersistOriginalValues },
}) {
  const clientTransactionId = uuid.create();
  try {
    const { powerformId } = params
    const server = getQueryStringValue('env')
    yield put(actions.setContext(params, query, hdnStartValues, hdnPersistOriginalValues, false))
    yield put(actions.startLoading())
    const { data, config } = yield call(
      api.fetchPowerform,
      server,
      powerformId,
      query,
      clientTransactionId
    )
    if (data.SigningResource) {
      setTranslations(data, languagesMapping[data.SigningResource.CultureInfo.toLowerCase()])
    }
    if (data.url) {
      redirect(data.url)
      return;
    }
    if (data.nextAction === "Error") {
      yield put(actions.stopLoading())
      yield put(actions.showMessage(
        `submit-powerform-error-${Date.now()}`,
        ERROR,
        data.ErrorMessage,
      ))
      yield put(actions.disableSubmitButton())
    } else if (data.nextAction === "CreateEnvelope") {
      const {
        Recipients,
        DssSettings,
        SuccessfullyActivated,
        AccountId
      } = data;
      const recipients = Array.isArray(Recipients) ?
        Recipients.map((recipient, index) => {
          // if the recipient's role has data present in start values, update it
          const userNameKey = recipient.Role + "_UserName";
          const emailKey = recipient.Role + "_Email";
          return {
            email: recipient.Email || hdnStartValues[emailKey],
            name: recipient.Name || hdnStartValues[userNameKey],
            isRequired: index === 0 || recipient.IsRequired,
            isConditional: recipient.IsConditional,
            order: recipient.Order,
            role: recipient.Role,
            type: recipient.Type
          }
        }) : [];
      const {
        EnablePowerformsV2_UI_Feature3,
        FRM_1438_EnablePowerFormsRestAPI
      } = DssSettings;
      yield put(actions.submitPowerform(
        server,
        recipients,
        powerformId,
        query,
        hdnStartValues,
        hdnPersistOriginalValues,
        SuccessfullyActivated,
        EnablePowerformsV2_UI_Feature3,
        clientTransactionId,
        FRM_1438_EnablePowerFormsRestAPI,
        true,
        AccountId
      ));
    } else {
      mixpanel.init(config.mixpanelEnv)
      mixpanel.track('Powerforms Landing Page', { 'New Powerforms': true })
      const updatedRecipients = data.Recipients
      if (updatedRecipients) {
        let allRecipientsHaveRoles = true;
        updatedRecipients.forEach(recipient => {
          const updatedRecipient = recipient
          // if the recipient's role has data present in start values, update it
          const userNameKey = recipient.Role + "_UserName"
          if (hdnStartValues[userNameKey]) {
            updatedRecipient.Name = hdnStartValues[userNameKey]
          }
          const emailKey = recipient.Role + "_Email"
          if (hdnStartValues[emailKey]) {
            updatedRecipient.Email = hdnStartValues[emailKey]
          }
          if (!recipient.Role) {
            allRecipientsHaveRoles = false;
          }
        })
        if (!allRecipientsHaveRoles) {
          const logData = JSON.stringify({
            accountId: getQueryParam(query, "accountId") || getQueryParam(query, "acct"),
            powerformId,
            clientTransactionId,
            Exception: "Recipient missing role"
          })
          api.logToKazmon(logData)
        }
        yield put(actions.updatePowerFormFromApi({ ...data, clientTransactionId, Recipients: updatedRecipients }))
        const errors = validateRecipients(data.Recipients, true)
        if (errors.length > 0) {
          yield put(actions.setPowerFormValidationErrors(errors))
        }
      } else if (data.status === "SentEmail") {
        yield put(actions.stopLoading())
        yield put(actions.setEmptyStatePowerFormRecipient())
        yield put(actions.showMessage(
          `sent-email`,
          INFO,
          data.SuccessfullyActivated || 'Your PowerForm has been successfully activated for signing. Email notifications have been sent.',
        ))
        yield put(actions.collapseForm())
      } else {
        yield put(actions.showMessage(
          `submit-powerform-error-${Date.now()}`,
          ERROR,
          getErrorMessageName(data.ErrorCode),
        ))
        if (data.ErrorCode === "inactive") {
          yield put(actions.collapseForm())
        }
      }
      yield put(actions.stopLoading())
    }
  } catch (error) {
    const isError429 = _.get(error, "status") === 429
    const errorContext = isError429 ? 'powerform_too_many_requests' : error

    yield put(actions.setErrorContext(errorContext))
    const errorCode = error ? errorContext : ''

    const baseLog = api.baseLogData()
    const data = JSON.stringify({
      stack: (typeof error === 'object' && error.stack) ? error.stack : error,
      message: (typeof error === 'object' && error.message) ? error.message : '',
      ...baseLog,
      clientTransactionId
    })
    yield call(
      api.logToKazmon,
      data
    )
    yield put(actions.collapseForm())
    yield put(actions.stopLoading())
    yield put(actions.showMessage(
      `fetch-powerform-error-${Date.now()}`,
      ERROR,
      getErrorMessageName({name: errorCode}),
    ))
  }
}

export function* handleSubmitPowerform({ payload: {
  powerformId,
  recipients,
  query,
  hdnStartValues,
  hdnPersistOriginalValues,
  successfullyActivated,
  EnablePowerformsV2_UI_Feature3,
  clientTransactionId,
  FRM_1438_EnablePowerFormsRestAPI,
  createEnvelope,
  accountId
} }) {
  // Validate all Recipients. If required and missing or if email is invalid, then add an error.
  const errors = createEnvelope ? [] : validateRecipients(recipients);
  try {
    if (errors.length > 0) {
      yield put(actions.setPowerFormValidationErrors(errors))
    } else {
      yield put(actions.startLoading())
      const server = getQueryStringValue('env')
      const newQuery = removeQueryStringValue(query, ['UserName', 'Email'])
      const { data, config } = yield call(
        api.postPowerform,
        server,
        powerformId,
        recipients,
        newQuery,
        hdnStartValues,
        hdnPersistOriginalValues,
        EnablePowerformsV2_UI_Feature3,
        clientTransactionId,
        FRM_1438_EnablePowerFormsRestAPI,
        accountId
      )
      if (FRM_1438_EnablePowerFormsRestAPI) {
        yield put(actions.setHasAlreadyPosted(true))
        switch (data.nextAction) {
          case "ActivationRequired":
          case "Signing":
            redirect(data.signingSiteRedirect.url)
            return;
          case  "Error":
            /* eslint-disable  no-case-declarations */
            const displayMessage = (() => {
              if (data.ErrorCode === "powerform_no_longer_signable") {
                return "This PowerForm is not accepting submissions now. To learn more, please contact your sender."
              } else {
                return `Unable to load PowerForm signing process. Error: ${data.errorMessage}`
              }
            })()
            const error = new Error(displayMessage)
            error.name = data.errorCode
            throw error
          case "SentEmail":
          case "EmailSent":
            yield put(actions.status(data.status, data.nextAction))
            yield put(actions.stopLoading())
            yield put(actions.setEmptyStatePowerFormRecipient())
            yield put(actions.showMessage(
              `sent-email`,
              INFO,
              successfullyActivated || 'Your PowerForm has been successfully activated for signing. Email notifications have been sent.',
            ))
            yield put(actions.collapseForm())
            break;
          default:
            yield put(actions.stopLoading())
            break;
        }
      } else {
        /* eslint-disable no-lonely-if */
        if (data.status === 'SentEmail') {
          yield put(actions.status(data.status))
          yield put(actions.stopLoading())
          yield put(actions.setEmptyStatePowerFormRecipient())
          yield put(actions.showMessage(
            `sent-email`,
            INFO,
            successfullyActivated || 'Your PowerForm has been successfully activated for signing. Email notifications have been sent.',
          ))
          yield put(actions.collapseForm())
        } else if (data.url) {
          mixpanel.identify(config.mixpanelDistinctId)
          mixpanel.track('Submitted Powerforms', { 'New Powerforms': true })
          redirect(data.url)
        } else if (data.ErrorCode) {
          const error = Error(data.ErrorMessage)
          error.name = data.ErrorCode
          throw error
        } else {
          yield put(actions.stopLoading())
        }
      }
    }
  } catch (error) {
    const isError429 = _.get(error, "status") === 429

    const errorContext = isError429 ? 'powerform_too_many_requests' : error

    yield put(actions.setErrorContext(errorContext))
    const errorCode = error ? errorContext : ''
    yield put(actions.stopLoading())
    yield put(actions.showMessage(
      `submit-powerform-error-${Date.now()}`,
      ERROR,
      getErrorMessageName(errorCode),
    ))
    yield put(actions.disableSubmitButton())
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* defineSagaListeners() {
  yield [
    takeLatest(SAGA_INIT_APP, handleInit),
    takeEvery(SAGA_POWERFORM_SUBMIT, handleSubmitPowerform),
    takeEvery(SAGA_LOCALE_CHANGE, handleLanguageChange),
  ]
}
