import lodash from "lodash";
import {
  POWERFORM_UPDATE_FROM_API,
  POWERFORM_RECIPIENT_UPDATE,
  POWERFORM_VALIDATION_ERROR_SET,
  POWERFORM_VALIDATION_ERROR_UNSET,
  POWERFORM_RECIPIENT_EMPTY_STATE_SET,
  POWERFORM_COLLAPSE,
  POWERFORM_DISABLE_SUBMIT_BUTTON,
  POWERFORM_STATUS_SET
} from '../actions'

const getUpdatedRecipients = (recipients, { role, value }) =>
  recipients.map(recipient => {
    if (recipient.role !== role) {
      return recipient;
    }
    return { ...recipient, ...value };
  });

const setEmptyStateRecipients = recipients => {
  return recipients.map(recipient => {
    return { recipient, name: "", email: "" };
  });
};

const mapRecipients = recipients => {
  if (Array.isArray(recipients)) {
    return recipients.map((recipient, index) => ({
      email: recipient.Email,
      name: recipient.Name,
      isRequired: index === 0 || recipient.IsRequired,
      isConditional: recipient.IsConditional,
      order: recipient.Order,
      role: recipient.Role,
      type: recipient.Type
    }));
  }
  return [];
};

const unsetError = (errors, { role, field }) =>
  errors &&
  errors.map(error => {
    if (error.role === role) {
      const { [field]: removed, ...rest } = error;
      return rest;
    }
    return error;
  });

const getLogoUrl = url => {
  const location = (() => {
    const loc = document.createElement("a");
    loc.href = url;
    return loc;
  })();

  if (url && location.hostname !== 'localhost' && /Images\/logo_docusign_blue\.png$/.test(url) === false) {
    return url;
  }

  return "";
};

export default function powerform(state = { recipients: [] }, action) {
  switch (action.type) {
    case POWERFORM_UPDATE_FROM_API:
      return {
        recipients: mapRecipients(action.payload.powerform.Recipients),
        brandDetails: action.payload.powerform.BrandDetails,
        recipientsLocked: action.payload.powerform.RecipientsLocked,
        errorCode: action.payload.powerform.ErrorCode,
        errorMessage: action.payload.powerform.ErrorMessage,
        dssSettings: action.payload.powerform.DssSettings,
        isCollapsed: false,
        successfullyActivated: action.payload.powerform.SuccessfullyActivated,
        nextAction: action.payload.powerform.NextAction,
        clientTransactionId: action.payload.powerform.clientTransactionId,
        accountId: action.payload.powerform.AccountId
      };
    case POWERFORM_RECIPIENT_UPDATE:
      return {
        ...state,
        recipients: getUpdatedRecipients(state.recipients, action.payload)
      };
    case POWERFORM_RECIPIENT_EMPTY_STATE_SET:
      return {
        ...state,
        recipients: setEmptyStateRecipients(state.recipients)
      };
    case POWERFORM_VALIDATION_ERROR_SET:
      return {
        ...state,
        errors: action.payload.errors
      };
    case POWERFORM_VALIDATION_ERROR_UNSET:
      return { ...state, errors: unsetError(state.errors, action.payload) };
    case POWERFORM_COLLAPSE:
      return { ...state, isCollapsed: true};
    case POWERFORM_DISABLE_SUBMIT_BUTTON:
      return { ...state, isSubmitButtonDisabled: true};
    case POWERFORM_STATUS_SET:
      return { ...state, status: action.payload.status, nextAction: action.payload.nextAction};
    default:
      return state;
  }
}

export const getRecipients = state => state.recipients;

export const getSuccessfullyActivated = state => state.successfullyActivated;

export const getStatus = state => state.status;

export const getRecipientsLocked = state => state.recipientsLocked;

export const getNextAction = state => state.nextAction;

export const getRecipientError = (state, role) => {
  const { errors } = state;
  return errors && lodash.find(errors, error => error.role === role);
};

export const getErrorCode = state => state.errorCode;

export const getErrorMessage = state => state.errorMessage;

export const getBrandDetails = state => {
  if (state === null || state.brandDetails === undefined) {
    return {};
  }

  return {
    headerBgColor: lodash.get(state.brandDetails, "HeaderBackground"),
    headerTxtColor: lodash.get(state.brandDetails, "HeaderTextColor"),
    btnPrimaryColor: lodash.get(state.brandDetails, "ButtonPrimaryBackground"),
    btnPrimaryTxtColor: lodash.get(
      state.brandDetails,
      "ButtonPrimaryTextColor"
    ),
    imgUrl: getLogoUrl(lodash.get(state.brandDetails, 'logoLeftUrl')),
    footerLinks: lodash.get(state.brandDetails, "footerLinks", []),
    companyName: lodash.get(state.brandDetails, "CompanyName"),
    brandLogo_AuthWhiteBorder: lodash.get(state.brandDetails, "BrandLogo_AuthWhiteBorder")
  };
};

export const getClientTransactionId = state => state.clientTransactionId;

export const getDssSettings = state => state.dssSettings;

export const getIsCollapsed = state => state.isCollapsed;

export const getIsSubmitButtonDisabled = state => state.isSubmitButtonDisabled;

export const getAccountId = state => state.accountId;
