/*
 * Returns specific query parameter value from search string
 * (URLSearchParams() is not supported by IE)
 *  
 * In the event a search string has repeated parameter values
 * in it, e.g. "?key1=value1&key1=value2", this function will
 * return the LAST occurring value
 * 
 * USAGE: getQueryParam("?key1=value1", "key1") returns "value1"
 */
export const getQueryParam = (searchString, param) => {
  // remove '?" from beginning of search string and then parse query parameters
  const queryParameters = searchString.slice(1).split("&").reduce((accumulator,value) => {
    const [parameterName, parameterValue] = value.split("=");
    accumulator[parameterName] = parameterValue;
    return accumulator;
  }, {});

  // return requested parameter
  return queryParameters[param];
};
