import { MESSAGE_SHOW, MESSAGE_HIDE } from '../actions'

export default function messages(
  state = {
    data: [],
  },
  action
) {
  switch (action.type) {
    case MESSAGE_SHOW:
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      }
    case MESSAGE_HIDE:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload.id),
      }
    default:
      return state
  }
}
