export function getErrorMessageName(error) {
  if (error === "inactive" || error === "1440") {
    return 'error.powerform_inactive_body'
  }
  switch (error && error.name) {
    case 'powerform_no_longer_signable':
      return 'error.powerform_not_available'
    case 'powerform_freeform_signing_not_allowed':
      return 'error.powerform_freeform_signing_not_allowed'
    case 'powerform_too_many_requests':
      return 'error.powerform_too_many_requests'
    default:
      if (error && error.message) {
        return error.message
      }
      return 'generic_error'
  }
}
