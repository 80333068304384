import { enablePseudoLoc } from '../config'
import en from './i18n/en.json'
import ar from './i18n/en_ar-SA.json'
import bg from './i18n/en_bg-BG.json'
import cs from './i18n/en_cs-CZ.json'
import da from './i18n/en_da-DK.json'
import de from './i18n/en_de-DE.json'
import el from './i18n/en_el-GR.json'
import gb from './i18n/en_en-GB.json'
import es from './i18n/en_es-ES.json'
import mx from './i18n/en_es-MX.json'
import et from './i18n/en_et-EE.json'
import fa from './i18n/en_fa-IR.json'
import fi from './i18n/en_fi-FI.json'
import ca from './i18n/en_fr-CA.json'
import fr from './i18n/en_fr-FR.json'
import he from './i18n/en_he-IL.json'
import hi from './i18n/en_hi-IN.json'
import hr from './i18n/en_hr-HR.json'
import hu from './i18n/en_hu-HU.json'
import hy from './i18n/en_hy-AM.json'
import id from './i18n/en_id-ID.json'
import it from './i18n/en_it-IT.json'
import ja from './i18n/en_ja-JP.json'
import ko from './i18n/en_ko-KR.json'
import lt from './i18n/en_lt-LT.json'
import lv from './i18n/en_lv-LV.json'
import ms from './i18n/en_ms-MY.json'
import no from './i18n/en_no-NO.json'
import nl from './i18n/en_nl-NL.json'
import pl from './i18n/en_pl-PL.json'
import br from './i18n/en_pt-BR.json'
import pt from './i18n/en_pt-PT.json'
import ro from './i18n/en_ro-RO.json'
import ru from './i18n/en_ru-RU.json'
import sk from './i18n/en_sk-SK.json'
import sl from './i18n/en_sl-SI.json'
import sr from './i18n/en_sr-RS.json'
import sv from './i18n/en_sv-SE.json'
import th from './i18n/en_th-TH.json'
import tr from './i18n/en_tr-TR.json'
import uk from './i18n/en_uk-UA.json'
import vi from './i18n/en_vi-VN.json'
import cn from './i18n/en_zh-CN.json'
import tw from './i18n/en_zh-TW.json'

// Other languages not used so not including them for now

export default {
  'en_ar-SA': ar,
  'en_bg-BG': bg,
  'en_cs-CZ': cs,
  'en_da-DK': da,
  'en_de-DE': de,
  'en_el-GR': el,
  'en_en-GB': gb,
  'en_en-US': en,
  'en_es-ES': es,
  'en_es-MX': mx,
  'en_et-EE': et,
  'en_fa-IR': fa,
  'en_fi-FI': fi,
  'en_fr-CA': ca,
  'en_fr-FR': fr,
  'en_he-IL': he,
  'en_hi-IN': hi,
  'en_hr-HR': hr,
  'en_hu-HU': hu,
  'en_hy-AM': hy,
  'en_id-ID': id,
  'en_it-IT': it,
  'en_ja-JP': ja,
  'en_ko-KR': ko,
  'en_lt-LT': lt,
  'en_lv-LV': lv,
  'en_ms-MY': ms,
  'en_no-NO': no,
  'en_nl-NL': nl,
  'en_pl-PL': pl,
  'en_pt-BR': br,
  'en_pt-PT': pt,
  'en_ro-RO': ro,
  'en_ru-RU': ru,
  'en_sk-SK': sk,
  'en_sl-SI': sl,
  'en_sr-RS': sr,
  'en_sv-SE': sv,
  'en_th-TH': th,
  'en_tr-TR': tr,
  'en_uk-UA': uk,
  'en_vi-VN': vi,
  'en_zh-CN': cn,
  'en_zh-TW': tw,
  en,
  en_PS: {},
}

export const LANGUAGES = [
  { code: 'en', description: 'English (US)' },
  { code: 'en_ar-SA', description: 'العربية‏' },
  { code: 'en_bg-BG', description: 'Български' },
  { code: 'en_cs-CZ', description: 'Čeština' },
  { code: 'en_da-DK', description: 'Dansk' },
  { code: 'en_de-DE', description: 'Deutsch' },
  { code: 'en_el-GR', description: 'Ελληνικά' },
  { code: 'en_en-GB', description: 'English (UK)' },
  { code: 'en_es-ES', description: 'Español' },
  { code: 'en_es-MX', description: 'Español (América Latina)' },
  { code: 'en_et-EE', description: 'Eesti' },
  { code: 'en_fa-IR', description: 'فارسی' },
  { code: 'en_fi-FI', description: 'Suomi' },
  { code: 'en_fr-CA', description: 'Française (Canada)' },
  { code: 'en_fr-FR', description: 'Français (France)' },
  { code: 'en_he-IL', description: 'עברית‏' },
  { code: 'en_hi-IN', description: 'हिन्दी' },
  { code: 'en_hr-HR', description: 'Hrvatski' },
  { code: 'en_hu-HU', description: 'Magyar' },
  { code: 'en_hy-AM', description: 'հայերեն' },
  { code: 'en_id-ID', description: 'Bahasa Indonesia' },
  { code: 'en_it-IT', description: 'Italiano' },
  { code: 'en_ja-JP', description: '日本語' },
  { code: 'en_ko-KR', description: '한국어' },
  { code: 'en_lt-LT', description: 'Lietuvių' },
  { code: 'en_lv-LV', description: 'Latviešu' },
  { code: 'en_ms-MY', description: 'Bahasa Melayu' },
  { code: 'en_no-NO', description: 'Norsk' },
  { code: 'en_nl-NL', description: 'Nederlandse' },
  { code: 'en_pl-PL', description: 'Polski' },
  { code: 'en_pt-BR', description: 'Português (Brasil)' },
  { code: 'en_pt-PT', description: 'Português (Portugal)' },
  { code: 'en_ro-RO', description: 'Română' },
  { code: 'en_ru-RU', description: 'Русский' },
  { code: 'en_sk-SK', description: 'Slovenčina' },
  { code: 'en_sl-SI', description: 'Slovenščina' },
  { code: 'en_sr-RS', description: 'Српски' },
  { code: 'en_sv-SE', description: 'Svenska' },
  { code: 'en_th-TH', description: 'ภาษาไทย' },
  { code: 'en_tr-TR', description: 'Türkçe' },
  { code: 'en_uk-UA', description: 'Українська' },
  { code: 'en_vi-VN', description: 'Việt' },
  { code: 'en_zh-CN', description: '中文 (简体)' },
  { code: 'en_zh-TW', description: '中文 (台灣)' },
]

export const languagesMapping = {
  ar: 'en_ar-SA',
  bg: 'en_bg-BG',
  cs: 'en_cs-CZ',
  da: 'en_da-DK',
  de: 'en_de-DE',
  el: 'en_el-GR',
  es: 'en_es-ES',
  et: 'en_et-EE',
  fa: 'en_fa-IR',
  fi: 'en_fi-FI',
  fr: 'en_fr-FR',
  he: 'en_he-IL',
  hi: 'en_hi-IN',
  hr: 'en_hr-HR',
  hu: 'en_hu-HU',
  hy: 'en_hy-AM',
  id: 'en_id-ID',
  it: 'en_it-IT',
  ja: 'en_ja-JP',
  ko: 'en_ko-KR',
  lt: 'en_lt-LT',
  lv: 'en_lv-LV',
  ms: 'en_ms-MY',
  no: 'en_no-NO',
  nl: 'en_nl-NL',
  pl: 'en_pl-PL',
  pt: 'en_pt-PT',
  ro: 'en_ro-RO',
  ru: 'en_ru-RU',
  sk: 'en_sk-SK',
  sl: 'en_sl-SI',
  sr: 'en_sr-RS',
  sv: 'en_sv-SE',
  th: 'en_th-TH',
  tr: 'en_tr-TR',
  uk: 'en_uk-UA',
  vi: 'en_vi-VN',
  'en-gb': 'en_en-GB',
  'en_GB': 'en_en-GB',
  'es-mx': 'en_es-MX',
  'es_MX': 'en_es-MX',
  'fr-ca': 'en_fr-CA',
  'fr_CA': 'en_fr-CA',
  'pt-br': 'en_pt-BR',
  'pt_BR': 'en_pt-BR',
  'zh-cn': 'en_zh-CN',
  'zh_CN': 'en_zh-CN',
  'zh-tw': 'en_zh-TW',
  'zh_TW': 'en_zh-TW',
  zh: 'en_zh-CN',
  en: 'en',
}

export const languages = [...LANGUAGES]
if (enablePseudoLoc) {
  languages.push({ code: 'en_PS', description: 'Pseudo Loc' })
}
