import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import actions from "../actions";
import { withTranslations } from "../lib/TranslationProvider";
import {
  getRecipients,
  getRecipientsLocked,
  getRecipientError
} from "../reducers/powerform";
import "./Powerforms.css";

const FormUnit = ({ children, hasError }) => (
  <div className={classNames("form_unit", { "form_unit-hasError": hasError })}>
    {children}
  </div>
);

const FormLabel = ({ required, children, htmlFor }) => (
  <label
    className="label"
    htmlFor={htmlFor}
    label-required={required ? "*" : null}
  >
    {children}
  </label>
);

FormLabel.defaultProps = {
  required: false
};

const TextInput = props => (
  <input
    name={props && props.name}
    disabled={props && props.disabled}
    onChange={props && props.onChange}
    required={props && props.required}
    type={props && props.type}
    value={props && props.value}
    className="input-text"
    aria-label={props && props.ariaLabel}
    autoComplete="on"
    placeholder={props && props.placeholder}
    data-qa={props && props.dataQa}
    aria-invalid={props && props.ariaInvalid}
    aria-describedby={props && props.ariaDescribedBy}
  />
);

const TextInputError = ({ value }) => (
  <p id="inputError" aria-live="polite" className="helper-text error-msg">{value}</p>
);

const FormInput = ({
  id,
  type = "text",
  kind,
  required,
  label,
  value,
  onChange,
  error,
  disabled,
  dataQa,
  placeholder,
  ariaLabel,
  ariaInvalid,
  ariaDescribedBy
}) => (
  <FormUnit hasError={!!error}>
    <FormLabel htmlFor={id} required={required}>
      {label}
    </FormLabel>
    <TextInput
      id={id}
      type={type}
      name={kind}
      ariaLabel={ariaLabel}
      onChange={onChange}
      value={value}
      disabled={disabled}
      dataQa={dataQa}
      placeholder={placeholder}
      required={required}
      ariaInvalid={ariaInvalid}
      ariaDescribedBy={ariaDescribedBy}
    />
    {error && <TextInputError value={error} />}
  </FormUnit>
);

TextInput.defaultProps = {
  required: false
};

const FormDisplay = ({ id, value, dataQa, t }) => (
  <FormUnit>
    <FormLabel htmlFor={id}>{t("form.group_name")}</FormLabel>
    <div id={id} data-qa={dataQa}>
      {value}
    </div>
  </FormUnit>
);

const FormTitle = ({ children }) => (
  <h3 className="form_unit" data-qa="powerform-dialog-role">
    {children}
  </h3>
);

const ConditionalRecipientEntry = props => {
  const { t } = props;
  return (
    <Fragment key={props.role}>
      <FormTitle>{t("form.conditional_recipient")}</FormTitle>
      <FormDisplay
        id={props.role}
        value={props.role}
        dataQa="powerform-dialog-role"
        t={t}
      />
    </Fragment>
  );
};

const RecipientEntry = props => {
  const { t } = props;
  const isFirst = props.index === 0;
  const required = props.recipient.isRequired;
  const recipientType = props.recipient.type;
  const nameError = props.error && props.error.name && t(props.error.name);
  const emailError = props.error && props.error.email && t(props.error.email);
  const disabled = props.recipientLocked && !isFirst;
  const onChange = (event, field, error) => {
    const { role } = props.recipient;
    if (error) {
      props.unsetValidationError(role, field);
    }
    props.updatePowerFormRecipient(role, { [field]: event.target.value });
  };
  const isConditionalRecipient = props.recipient.isConditional;

  if (isConditionalRecipient) {
    return (
      <ConditionalRecipientEntry
        name={props.recipient.name}
        role={props.recipient.role}
        t={t}
      />
    );
  }

  return (
    <Fragment key={props.recipient.role}>
      <FormTitle>{props.recipient.role}</FormTitle>
      <FormInput
        kind="name"
        label={isFirst ? t("YourNameText") : t("NameText")}
        placeholder={t("form.full_name")}
        required={required}
        value={props.recipient.name}
        error={nameError}
        disabled={disabled}
        dataQa="powerform-dialog-username"
        onChange={event => onChange(event, "name", nameError)}
        ariaLabel={isFirst ? t("YourNameText") : props.recipient.role + " " + t("NameText")}
        ariaInvalid={props.error && props.error.name ? "true" : "false"}
        ariaDescribedBy={props.error && props.error.name ? "inputError" : null}
      />
      {recipientType !== "SealSign" && <FormInput
        kind="email"
        label={isFirst ? t("YourEmailText") : t("EmailText")}
        placeholder={t("form.email_address")}
        required={required}
        value={props.recipient.email}
        error={emailError}
        disabled={disabled}
        dataQa="powerform-dialog-email"
        onChange={event => onChange(event, "email", emailError)}
        ariaLabel={isFirst ? t("YourEmailText") : props.recipient.role + " " + t("EmailText")}
        ariaInvalid={props.error && props.error.email ? "true" : "false"}
        ariaDescribedBy={props.error && props.error.email ? "inputError" : null}
      />}
    </Fragment>
  );
};

const RecipientEntryComponent = connect(
  (state, ownProps) => ({
    error: getRecipientError(state.powerform, ownProps.recipient.role)
  }),
  dispatch =>
    bindActionCreators(
      {
        unsetValidationError: actions.unsetValidationError,
        updatePowerFormRecipient: actions.updatePowerFormRecipient
      },
      dispatch
    )
)(RecipientEntry);

const RecipientsEntryList = ({ recipients, recipientsLocked, t, dsih }) => {
  const recipientsList = recipients.map((recipient, index) => (
    <RecipientEntryComponent
      key={recipient.role}
      recipient={recipient}
      recipientLocked={recipientsLocked}
      index={index}
      t={t}
    />
  ));
  if (recipients.length > 1) {
    const extraInstructions = dsih ? (
      <p data-qa="dialog-provide-signer-info" key="extraInstructions" dangerouslySetInnerHTML={{__html: t("EnterOtherSignerInfo")}}></p>
    ) : (
      <p data-qa="dialog-provide-signer-info" key="extraInstructions">{t("EnterOtherSignerInfo")}</p>
    );
    recipientsList.splice(1, 0, extraInstructions);
  }
  return <div>{recipientsList}</div>;
};

const TranslatedRecipientsEntryList = withTranslations(RecipientsEntryList);

export default connect(
  state => ({
    recipients: getRecipients(state.powerform),
    recipientsLocked: getRecipientsLocked(state.powerform)
  }),
  null
)(TranslatedRecipientsEntryList);
