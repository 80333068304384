import { LOCALE_SET } from '../actions'

export default function locale(state = null, action) {
  switch (action.type) {
    case LOCALE_SET:
      return action.payload
    default:
      return state
  }
}
