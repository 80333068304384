//
// SAGAS
//
export const SAGA_LOCALE_CHANGE = 'SAGA_LOCALE_CHANGE'
export const SAGA_POWERFORM_SUBMIT = 'SAGA_POWERFORM_SUBMIT'
export const SAGA_INIT_APP = 'SAGA_INIT_APP'

//
// UPDATES
//
export const CONTEXT_SET = 'CONTEXT_SET'
export const MESSAGE_SHOW = 'MESSAGE_SHOW'
export const MESSAGE_HIDE = 'MESSAGE_HIDE'
export const LOADING_START = 'LOADING_START'
export const LOADING_STOP = 'LOADING_STOP'
export const POWERFORM_RECIPIENT_UPDATE = 'POWERFORM_RECIPIENT_UPDATE'
export const POWERFORM_VALIDATION_ERROR_SET = 'POWERFORM_VALIDATION_ERROR_SET'
export const POWERFORM_VALIDATION_ERROR_UNSET = 'POWERFORM_VALIDATION_ERROR_UNSET'
export const POWERFORM_UPDATE_FROM_API = 'POWERFORM_UPDATE_FROM_API'
export const POWERFORM_RECIPIENT_EMPTY_STATE_SET = 'POWERFORM_RECIPIENT_EMPTY_STATE_SET'
export const POWERFORM_COLLAPSE = 'POWERFORM_COLLAPSE'
export const POWERFORM_DISABLE_SUBMIT_BUTTON = 'POWERFORM_DISABLE_SUBMIT_BUTTON'
export const POWERFORM_STATUS_SET = 'POWERFORM_STATUS_SET'
export const LOCALE_SET = 'LOCALE_SET'

export function createAction(type, payload) {
  const action = { type, payload }
  if (payload instanceof Error) {
    action.error = true
  }
  return action
}

const actions = {}

/**
 * @param {object} params
 * @param {string} query
 * @param {object} hdnStartValues
 * @param {object} hdnPersistOriginalValues
 */
actions.initApp = (params, query, hdnStartValues, hdnPersistOriginalValues) => {
  return createAction(SAGA_INIT_APP, { params, query, hdnStartValues, hdnPersistOriginalValues })
}
/**
 * @param {string} langCode
 */
actions.changeLocale = langCode => createAction(SAGA_LOCALE_CHANGE, { langCode })
/**
 * Submit recipients form to the API if valid data
 * @param {string} server
 * @param {Array} recipients
 * @param {string} powerformId
 * @param {string} query
 * @param {Array} hdnStartValues
 * @param {Array} hdnPersistOriginalValues
 */
actions.submitPowerform = (server,
  recipients,
  powerformId,
  query,
  hdnStartValues,
  hdnPersistOriginalValues,
  successfullyActivated,
  EnablePowerformsV2_UI_Feature3,
  clientTransactionId,
  FRM_1438_EnablePowerFormsRestAPI,
  createEnvelope,
  accountId) =>
  createAction(SAGA_POWERFORM_SUBMIT, {
    server,
    recipients,
    powerformId,
    query,
    hdnStartValues,
    hdnPersistOriginalValues,
    successfullyActivated,
    EnablePowerformsV2_UI_Feature3,
    clientTransactionId,
    FRM_1438_EnablePowerFormsRestAPI,
    createEnvelope,
    accountId
  })

// Context
/**
 * @param {object} params
 * @param {string} query
 * @param {object} hdnStartValues
 * @param {object} hdnPersistOriginalValues
 * @param {object} apiError
 */
actions.setContext = (params, query, hdnStartValues, hdnPersistOriginalValues, hasAlreadyPosted) => createAction(CONTEXT_SET, { params, query, hdnStartValues, hdnPersistOriginalValues, hasAlreadyPosted })

/**
 * @param {object} params
 * @param {string} query
 * @param {object} hdnStartValues
 * @param {object} hdnPersistOriginalValues
 * @param {object} apiError
 */
actions.setErrorContext = (apiError) => createAction(CONTEXT_SET, { apiError })

// Messages
/**
 * @param {string} id
 * @param {'info' | 'error' | 'success' | 'warning'} kind
 * @param {string} text
 */
actions.showMessage = (id, kind, text) => createAction(MESSAGE_SHOW, { id, kind, text })
/**
 * @param {string} id
 */
actions.hideMessage = id => createAction(MESSAGE_HIDE, { id })

// Loading
actions.startLoading = () => createAction(LOADING_START)
actions.stopLoading = () => createAction(LOADING_STOP)

// Powerforms
/**
 * Set powerform data returned from API
 * @param {object} powerform
 */
actions.updatePowerFormFromApi = powerform =>
  createAction(POWERFORM_UPDATE_FROM_API, { powerform })

/**
 * Update Recipient fields
 * @param {string} role - recipient role, used as unique id
 * @param {object} value - either email or name field
 * @param {string} value.email
 * @param {string} value.name
 */
actions.updatePowerFormRecipient = (role, value) =>
  createAction(POWERFORM_RECIPIENT_UPDATE, { role, value })

actions.setEmptyStatePowerFormRecipient = (role, value) =>
  createAction(POWERFORM_RECIPIENT_EMPTY_STATE_SET)
/**
 * @typedef {Object} RecipientError
 * @field {string} role - recipient role
 * @field {string} name - recipient name
 * @field {string} email - recipient email
 */
/**
 * Set recipient validation errors
 * @param {[RecipientError]} errors
 */
actions.setPowerFormValidationErrors = errors =>
  createAction(POWERFORM_VALIDATION_ERROR_SET, { errors })

actions.unsetValidationError = (role, field) =>
  createAction(POWERFORM_VALIDATION_ERROR_UNSET, { role, field })

actions.collapseForm = () => createAction(POWERFORM_COLLAPSE)

actions.disableSubmitButton = () => createAction(POWERFORM_DISABLE_SUBMIT_BUTTON)

actions.setLocale = langCode =>
  createAction(LOCALE_SET, langCode)

actions.status = (status, nextAction) => createAction(POWERFORM_STATUS_SET, { status, nextAction })

actions.setHasAlreadyPosted = hasAlreadyPosted => createAction(CONTEXT_SET, { hasAlreadyPosted })

export default actions
