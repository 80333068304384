import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ToastMessage } from '@ds/ui'
import actions from '../actions'
import { withTranslations } from '../lib/TranslationProvider'

const Notifications = ({ messages = [], onHideMessage, t }) => (
  <Fragment>
    {messages.map(message => (
      <ToastMessage
        key={message.id}
        kind={message.kind}
        visible
        handleClose={() => onHideMessage(message.id)}
      >
        {t(message.text)}
      </ToastMessage>
    ))}
  </Fragment>
)

export const TranslatedNotifications = withTranslations(Notifications)

export default connect(
  state => ({
    messages: state.messages.data,
  }),
  dispatch => bindActionCreators({
    onHideMessage: actions.hideMessage,
  }, dispatch)
)(TranslatedNotifications)
