import 'cross-fetch/polyfill'
import 'formdata-polyfill'
import _ from 'lodash'
import queryString from 'query-string'
import { uuid } from "@ds/sidekick";
import { hosts, powerformEndpoint, enableMonitoring } from '../config'
import { getUserLocale } from './translate'
import {
  validateManagedTokenParams,
  buildManagedTokenRedeemPath,
  buildParamObject,
} from './api_helpers'
import { getQueryParam } from './getQueryParam'

export const getHeaders = (language, clientTransactionId, url, env) => {
  const headers = {
    accept: 'application/json'
  }

  if (url.slice(-8) === "/signing") {
    if (clientTransactionId) {
      headers['x-docusign-clienttransactionid'] = clientTransactionId;
    }
    headers['x-docusign-environment'] = env;
  }

  // TODO: add test to test browser cookies for languages
  if (language) {
    headers['accept-language'] = language;
  }

  return headers
}

export const baseLogData = () => ({
  'user-agent': window.navigator.userAgent,
  os: window.navigator.platform,
  path: window.location.pathname,
  hostname: window.location.host,
})

export const logToKazmon = (logData) => {
  const logHeaders = {
    'Content-Type': 'application/json',
  }
  const reqConfig = { method: 'POST', body: logData, headers: logHeaders }
  if (enableMonitoring) {
    fetch('/log', reqConfig)
  }
}

const logRequestToKazmon = (url, requestConfig) => {
  if (!enableMonitoring) {
    return
  }
  const urlIndex = url.indexOf('?')
  let paramPairs = {}
  let reqUrl = url
  if (urlIndex > -1) {
    paramPairs = buildParamObject(url.substring(urlIndex))
    reqUrl = url.substring(0, urlIndex)
  }

  const baseLog = baseLogData()
  const reqMetaData = { ...requestConfig, ...baseLog, ...paramPairs }

  const logData = JSON.stringify({
    ...reqMetaData,
    url: /^(https?:\/\/)/i.test(reqUrl) ? reqUrl : window.origin + reqUrl,
  })

  logToKazmon(logData)
}

export const buildUrl = (host, path, powerformId, queryParams = '') => {
  const modifiedQueryParams = queryParams.replace('?', '&')
  return `${host}${path}?${queryString.stringify({ PowerFormId: powerformId })}${modifiedQueryParams}`
}

export const powerformToFormData = (recipients, hdnStartValues, hdnPersistOriginalValues) => {
  const formData = _.reduce(recipients, (data, recipient, index) => {
    if (recipient) {
      data.append(`${recipient.role}_UserName`, recipient.name)
      data.append(`${recipient.role}_Email`, recipient.email)

      if (recipient.isConditional) {
        data.append(`hdnRecipientIsConditional_${recipient.order}`, true)
      }
    }
    return data
  }, new FormData())

  for (const key in hdnStartValues) {
    if (!formData.get(key)) {
      formData.append(key, hdnStartValues[key])
    }
  }

  for (const key in hdnPersistOriginalValues) {
    if (!formData.get(key)) {
      formData.append(key, hdnPersistOriginalValues[key])
    }
  }

  return formData
}

export const request = (url, config, queryParams, originalParams) => {
  const appConfig = {}
  const credentials = 'same-origin'
  const {
    apiHost,
    body,
    clientTransactionId,
    language,
    method
  } = config
  const accountId = getQueryParam(queryParams, "accountId") || getQueryParam(queryParams, "acct")
  const env = getQueryParam(queryParams, "env").toLowerCase()
  const headers = getHeaders(language, clientTransactionId, url, env)
  const powerformId = window.location.pathname.slice(1)

  const isEmbedded = (() => {
    try {
      return window.self !== window.top
    } catch (error) {
      // IE
      return true
    }
  })();

  // log request to kazmon
  logRequestToKazmon(url, {
    accountId,
    clientTransactionId,
    env,
    isEmbedded,
    decodedParams: queryParams.slice(1).split("&").reduce((acc, kvPair) => {
      const [key, value] = kvPair.split("=");
      acc[key] = value;
      return acc;
    }, {}),
    rawParams: originalParams ? originalParams.slice(1).split("&").reduce((acc, kvPair) => {
      const [key, value] = kvPair.split("=");
      acc[key] = value;
      return acc;
    }, {}) : "",
    referer: window.location.origin,
    requestHeaders: headers,
    method: method || 'GET',
    powerformId,
    status: 'requested'
  })
  const requestStartTime = Date.now()
  return fetch(url, { ...config, headers, credentials })
    .then((response) => {
      const endTime = Date.now()
      const {
        bodyUsed,
        headers,
        ok,
        redirected,
        status,
        statusText,
        type,
        url
      } = response
      const headersArray = []
      response.headers.forEach((value, key) => {
        headersArray.push(`${key}: ${value}`)
      })
      const headersStr = headersArray.join("\n\t")
      // log status to kazmon
      if (response.ok) {
        logRequestToKazmon(url, {
          accountId,
          bodyUsed,
          clientTransactionId,
          duration: Date.now() - requestStartTime,
          env,
          responseHeaders: headersStr,
          ok,
          method: method || 'GET',
          powerformId,
          redirected,
          status,
          statusText,
          type,
          url
        })
        appConfig.mixpanelEnv = response.headers.get('mixpanel-env')
        appConfig.mixpanelDistinctId = response.headers.get('mixpanel-distinctid')
        return response.json()
      }
      // log error to kazmon
      logRequestToKazmon(url, {
        accountId,
        bodyUsed,
        clientTransactionId,
        duration: Date.now() - requestStartTime,
        env,
        error: response,
        referer: window.location.origin,
        responseHeaders: headersStr,
        method: method || 'GET',
        ok,
        powerformId,
        redirected,
        status,
        statusText,
        type,
        url
      })
      return Promise.reject(response)
    })
    .then(data => ({ data, config: appConfig }))
}

export const fetchTranslations = langCode =>
  request(`/i18n/${langCode}.json`).then(({ data }) => data).catch(e => e)

export const fetchPowerform = (apiHost, powerformId, queryParams, clientTransactionId) => {
  const decodedQueryParams = queryParams.replace(/amp;/gi, "").replace(/amp%3B/gi, "")
  const url = buildUrl(
    hosts[apiHost],
    powerformEndpoint,
    powerformId,
    decodedQueryParams
  )

  /**
   * the format returned by getUserLocale: en_wx_yz (for our frontend json
   * files) but we only want to pass wx_yz to the backend api
   */
  const language = getUserLocale().split(/_(.+)/)[1] || 'en' // in case nothing is returned, we default to english
  return request(url, { apiHost, language }, decodedQueryParams, queryParams)
    .then(apiData => {
      const usePfApi = _.get(apiData, ["data", "DssSettings", "FRM_1438_EnablePowerFormsRestAPI"]);
      const memberAccountId =  _.get(apiData, ["data", "AccountId"]);
      if (usePfApi) {
        const accountId = getQueryParam(decodedQueryParams, "accountId") || getQueryParam(decodedQueryParams, "acct") || memberAccountId
        const nodeProxyUrl = `api/accounts/${accountId}/powerforms/${powerformId}/signing`;
        return request(nodeProxyUrl, { apiHost, language, clientTransactionId }, decodedQueryParams)
          .then(({data, config}) => {
            if (data.nextAction === "Error") {
              return {
                data: {
                  nextAction: data.nextAction,
                  ErrorCode: data.errorCode,
                  ErrorMessage: data.errorMessage
                }, 
                config
              };
            }
            // reshape data
            const CapitalizedStrings = Object.keys(data.signingResourceAndInstructionsStrings).reduce((accumulator, currentStringName) => {
              const capitalizedStringName = currentStringName[0].toUpperCase() + currentStringName.slice(1);
              accumulator[capitalizedStringName] = data.signingResourceAndInstructionsStrings[currentStringName];
              return accumulator;
            }, {});

            const queryObject = queryParams ? queryParams.slice(1).split("&").reduce((acc, pair) => {
              const [key, value] = pair.split("=");
              acc[key.replace("amp;","").replace("amp%3B","")] = value;
              return acc;
            }, {}) : {};

            const updatedRecipients = data.recipients.map(recipient => {
              if (recipient.Name === "") {
                const recipientNameKey = recipient.Role + "_UserName";
                // eslint-disable-next-line no-param-reassign
                recipient.Name = queryObject[recipientNameKey] ? decodeURIComponent(queryObject[recipientNameKey]) : "";
              }
              if (recipient.Email === "") {
                const recipientEmailKey = recipient.Role + "_Email";
                // eslint-disable-next-line no-param-reassign
                recipient.Email = queryObject[recipientEmailKey] ? decodeURIComponent(queryObject[recipientEmailKey]) : "";
              }
              return recipient;
            });

            const reshapedData = {
              ...CapitalizedStrings,
              BrandDetails: data.brandDetails,
              DssSettings: data.dssSettings,
              ErrorCode: data.errorCode,
              ErrorMessage: data.errorMessage,
              Recipients: updatedRecipients,
              SigningResource: data.signingResourceAndInstructionsStrings.signingResource,
              nextAction: data.nextAction,
              AccountId: memberAccountId
            }
            return {data: reshapedData, config};
          });
      } else {
        return apiData;
      }
    })
}

export const postPowerform = (
  apiHost,
  powerformId,
  recipients,
  query,
  hdnStartValues,
  hdnPersistOriginalValues,
  EnablePowerformsV3_UI_Feature3,
  clientTransactionId,
  FRM_1438_EnablePowerFormsRestAPI,
  memberAccountId
) => {
  let decodedQuery = query.replace(/amp;/gi, "").replace(/amp%3B/gi, "");
  if (EnablePowerformsV3_UI_Feature3) {
    Object.keys(hdnStartValues).forEach(key => {
      const decodedKey = key.replace(/amp;/gi, "").replace(/amp%3B/gi, "");
      if (decodedKey.indexOf("_UserName") > -1 || decodedKey.indexOf("_Email") > -1) {
        /* eslint-disable no-param-reassign */
        decodedQuery += `&${decodedKey}=${encodeURIComponent(hdnStartValues[key])}`
      }
    })
  }

  if (FRM_1438_EnablePowerFormsRestAPI) {
    const accountId = getQueryParam(decodedQuery, "accountId") || getQueryParam(decodedQuery, "acct") || memberAccountId
    const path = `api/accounts/${accountId}/powerforms/${powerformId}/signing`;
    /* eslint-disable no-param-reassign */
    recipients = recipients.map((recipient) => {
      if (recipient) {
        const {role, name, email, isConditional, type} = recipient;
        return {
          role: decodeURIComponent(role),
          email,
          isConditional,
          type,
          userName: name
        }
      }
      return {};
    })

    const fields = []
    const tabs = []
    Object.keys(hdnStartValues).forEach(hsv => {
      const paramKey = hsv.toLowerCase();
      if (hsv.indexOf("_UserName") > -1 || hsv.indexOf("_Email") > -1) {
        // do nothing
      } else if (paramKey === "activateonly" || paramKey === "env" || paramKey === "acct" || paramKey === "accountId" || paramKey === "t_dss") {
        // do nothing
      } else if (hsv.indexOf("EnvelopeField_") === 0) {
        fields.push({ "fieldName": hsv.slice(14), "value": hdnStartValues[hsv] })
      } else {
        const hsvPartition = hsv.indexOf("_");
        if (hsvPartition > 0) {
          const role = hsv.slice(0, hsvPartition);
          const tabLabel = hsv.slice(hsvPartition + 1);
          tabs.push({ role, tabLabel, "value": hdnStartValues[hsv] })
        } else {
          tabs.push({ "role": "", "tabLabel": hsv, "value": hdnStartValues[hsv] })
        }
      }
    })

    const body = JSON.stringify({
      languageCode: "EN-US",
      activateOnly: getQueryParam(decodedQuery.toLowerCase(), "activateonly") === "1",
      persistOriginal: false,
      recipients,
      fields,
      tabs
    });

    const requestConfig = {
      apiHost,
      method: 'POST',
      body,
      clientTransactionId
    }
    return request(path, requestConfig, decodedQuery, query)
      .then(({data, config}) => {
        return {data: data.data, config};
      })
      .catch(error => error)
  } else {
    const url = buildUrl(
      hosts[apiHost],
      powerformEndpoint,
      powerformId,
      decodedQuery
    )
    const requestConfig = {
      apiHost,
      method: 'POST',
      body: powerformToFormData(recipients, hdnStartValues, hdnPersistOriginalValues),
      clientTransactionId
    }
    return request(url, requestConfig, decodedQuery, query)
      .then(({ data, config }) => ({ data, config }))
  }
}

const fetchAccessToken = (apiHost, path) => {
  const hostMinusProtocol = _.chain(hosts[apiHost || 'default'])
    .split('://')
    .last()
    .value()
  const url = `https://account.${hostMinusProtocol}/${path}`

  return fetch(url, {})
    .then(response => (response.ok ? response.json() : Promise.reject(response)))
    .then(data => data)
}

export const redeemManagedToken = (apiHost, params) => validateManagedTokenParams(params)
  .then(buildManagedTokenRedeemPath)
  .then(_.partial(fetchAccessToken, apiHost))
