import _ from 'lodash'

const buildParamString = (paramObj, paramArr) =>
  _.chain(paramObj)
    .pick(paramArr)
    .toPairs()
    .map(_.partial(_.join, _, '='))
    .join('&')
    .value()

export const buildParamObject = paramStr =>
  _.chain(paramStr)
    .split('?')
    .last()
    .replace("amp;", "")
    .replace("amp%3B", "")
    .split('&')
    .map(_.partial(_.split, _, '=', 2))
    .fromPairs()
    .value()

const areParamsPresent = (paramStr, paramArr) => {
  if (_.isNil(paramStr)) return false

  const paramObj = buildParamObject(paramStr)

  return _.every(paramArr, param => !_.isNil(paramObj[param]))
}

export const buildManagedTokenRedeemPath = (paramStr) => {
  const accountServerMtRedeemPath = 'managed_token/v1/redeem'
  const paramObj = buildParamObject(paramStr)
  const paramString = buildParamString(
    paramObj,
    ['mtid', 'mtsec']
  )

  return new Promise(resolve =>
    resolve(`${accountServerMtRedeemPath}?${paramString}`))
}

export const validateManagedTokenParams = (paramStr) => {
  const paramArr = ['mtid', 'mtsec']

  return new Promise((resolve, reject) => {
    if (areParamsPresent(paramStr, paramArr)) {
      resolve(paramStr)
    } else {
      reject(new Error('Required Params Missing.'))
    }
  })
}
