import mixpanel from 'mixpanel-browser';

import { mixpanelId } from '../config'

let doneIniting = false;
const isLoaded = mixpanelFunction =>
  Boolean(mixpanel && mixpanel[mixpanelFunction]);

export const init = () => {
  if (!isLoaded("init")) return;
  const loaded = () => {
    doneIniting = true;
  };
  mixpanel.init(mixpanelId, { loaded })
}

export const identify = (distinctId) => {
  mixpanel.identify(distinctId)
}

export const track = (event, properties) => {
  if (!doneIniting || !isLoaded("track")) return;
  mixpanel.track(event, properties)
}
