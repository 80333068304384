import _ from 'lodash'
import queryString from 'query-string'
import { createTranslate, pseudoConvert } from '@ds/translator'
import translations, { languagesMapping } from './lang'
import sanitizer from "@docusignsecurity/html-sanitizer"

sanitizer.allowedAttributes.p = ['style']

export const DEFAULT_LANG_CODE = 'en'

export const getUserLocale = () => {
  const parsed = queryString.parse(window.location.search)
  const cookie = _.find(document.cookie.split(';'), val => val.indexOf('DocuSignLanguage') > -1)
  if (parsed.lang) {
    return parsed.lang
  } else if (parsed.recipientLang) {
    const mappedRecipientLanguage = languagesMapping[parsed.recipientLang];
    if (mappedRecipientLanguage) {
      return mappedRecipientLanguage;
    }

  } else if (cookie) {
    return cookie.split('=')[1];
  }

  const browserLanguage = window.navigator.userLanguage || window.navigator.language
  const browserLanguageMapping = languagesMapping[browserLanguage.toLowerCase().replace('_', '-')] ||
                                   languagesMapping[browserLanguage.replace('_', '-').split(/-(.+)/)[0]]
  if (browserLanguageMapping) {
    return browserLanguageMapping
  }
  return DEFAULT_LANG_CODE
}

const translationsOverridden = { ...translations }
const translators = {}

export const setTranslations = (values, langCode) => {
  _.flatten([langCode]).forEach((lang) => {
    translationsOverridden[lang] =
        { ...translationsOverridden[lang], ...values }
  })
}

// eslint-disable-next-line no-shadow
const createTranslation = (input, langCode) => (createTranslate((input) => {
  if (langCode === 'en_PS') {
    return pseudoConvert(input)
  }
  return (translationsOverridden[langCode] &&
    translationsOverridden[langCode][input]) || input
}))

const translate = (input, langCode, dsih) => {
  translators[langCode] = translators[langCode] || createTranslation(input, langCode)
  if (dsih) {
    return sanitizer.sanitize(translators[langCode](input))
  }
  return translators[langCode](input).replace(/<(.|\n)*?>/g, '').replace(/[&]nbsp[;]/gi, '')
}

export default translate
