import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.element,
  location: PropTypes.string.isRequired,
  full: PropTypes.string,
  backgroundColor: PropTypes.string,
}

const defaultProps = {
  children: null,
  full: '',
  backgroundColor: '#ffffff',
}

class StickyWrapper extends React.Component {
  constructor() {
    super()

    this.handleScroll = this.handleScroll.bind(this)
    this.state = {
      currentlySticking: false,
      previousWindowWidth: null,
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }

  // setPosition: check if we need to make the element sticky
  // when the scroll event hides the element
  // if needs to be sticky then, set the position of the sticky element
  // by adding sticky css which makes it position fixed
  setPosition() {
    const elementHeight = this.sticky.offsetHeight
    const elementOffsetTop = this.wrapdiv.offsetTop
    const containerHeight = window.innerHeight
    const containerScrollTop = window.scrollY
    const windowWidth = window.outerWidth

    let makeSticky = false
    let locationCss = null

    // if sticky element needs to be on the top,
    // set the sticky-top olive css else sticky-bottom olive css
    if (this.props.location === 'top') {
      locationCss = 'sticky-top'
      makeSticky = containerScrollTop > elementOffsetTop
    } else if (this.props.location === 'bottom') {
      locationCss = 'sticky-bottom'
      makeSticky = (containerScrollTop + containerHeight) < (elementOffsetTop + elementHeight)
    }

    // join all the required sticky class and the current class
    const classes = [
      'content_actions',
      'sticky',
      locationCss,
    ].join(' ')

    // if needs to be sticky, apply the sticky classes
    // and set parent height and set offset else remove all sticky classes
    if (makeSticky && (!this.state.currentlySticking ||
        (this.state.previousWindowWidth !== windowWidth))) {
      this.setState({ currentlySticking: true, previousWindowWidth: windowWidth })

      // must set parent height and offset before setting the "sticky" class
      this.setParentHeight()
      this.setOffset()

      this.sticky.className = classes
    } else if ((!makeSticky) && this.state.currentlySticking) {
      this.setState({ currentlySticking: false, previousWindowWidth: windowWidth })

      this.sticky.className = 'content_actions'

      this.setParentHeight()
      this.setOffset()
    }

    // // save the window width to use for next time
    // this.setState({ previousWindowWidth: windowWidth })
  }

  // setParentHeight: Calculate the height of the sticky element and set it
  // on the parent container so the content below doesn't jump
  setParentHeight() {
    const style = window.getComputedStyle(this.sticky)
    const requireHeight = this.state.currentlySticking
      || style.position === 'absolute'
      || style.position === 'fixed'

    const height = requireHeight
      ? this.sticky.offsetHeight
      : ''

    this.wrapdiv.style.height = height
  }

  // setOffset: calculate the offset of the sticky
  // so it maintains its position on screen and container's width
  setOffset() {
    const css = {
      left: '',
      width: '',
      backgroundColor: '',
    }

    if (this.state.currentlySticking) {
      if (this.props.full) {
        css.left = 0
        css.width = '100%'
        css.backgroundColor = this.props.backgroundColor
      } else {
        css.left = this.wrapdiv.offsetLeft
        css.width = this.wrapdiv.offsetWidth
      }
    }

    this.sticky.style.left = css.left
    this.sticky.style.width = css.width
    this.sticky.style.background = css.backgroundColor
  }

  handleScroll() {
    this.wrapdiv.minHeight = this.sticky.clientHeight
    this.setPosition()
  }

  render() {
    return (
      <div ref={(node) => { this.wrapdiv = node }} style={{ minHeight: 32 }}>
        <div className="content_actions" ref={(node) => { this.sticky = node }}>
          <div className="actions_wrap">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

StickyWrapper.propTypes = propTypes
StickyWrapper.defaultProps = defaultProps

export default StickyWrapper
