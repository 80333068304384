import * as i18nlayer from '@ds/i18nlayer'

export const langMapToi18nlayer = {
  en: 'en',
  'en_ar-SA': 'ar',
  'en_bg-BG': 'bg',
  'en_cs-CZ': 'cs',
  'en_da-DK': 'da',
  'en_de-DE': 'de',
  'en_el-GR': 'el',
  'en_en-GB': 'en_gb',
  'en_en-US': 'en',
  'en_es-ES': 'es',
  'en_es-MX': 'es_mx',
  'en_et-EE': 'et',
  'en_fa-IR': 'fa',
  'en_fi-FI': 'fi',
  'en_fr-CA': 'fr_ca',
  'en_fr-FR': 'fr',
  'en_he-IL': 'he',
  'en_hi-IN': 'hi',
  'en_hr-HR': 'hr',
  'en_hu-HU': 'hu',
  'en_hy-AM': 'hy',
  'en_id-ID': 'id',
  'en_it-IT': 'it',
  'en_ja-JP': 'ja',
  'en_ko-KR': 'ko',
  'en_lt-LT': 'lt',
  'en_lv-LV': 'lv',
  'en_ms-MY': 'ms',
  'en_no-NO': 'no',
  'en_nl-NL': 'nl',
  'en_pl-PL': 'pl',
  'en_pt-BR': 'pt_br',
  'en_pt-PT': 'pt',
  'en_ro-RO': 'ro',
  'en_ru-RU': 'ru',
  'en_sk-SK': 'sk',
  'en_sl-SI': 'sl',
  'en_sr-RS': 'sr',
  'en_sv-SE': 'sv',
  'en_th-TH': 'th',
  'en_tr-TR': 'tr',
  'en_uk-UA': 'uk',
  'en_vi-VN': 'vi',
  'en_zh-CN': 'zh_cn',
  'en_zh-TW': 'zh_tw',
  en_PS: 'en',
}

export const getLink = (item, lang) => i18nlayer.getLink(item, langMapToi18nlayer[lang] || lang)
export default getLink
