import React from 'react'

export const TranslationContext =
  React.createContext()

class TranslationProvider extends React.Component {
  translate(text) {
    return this.props.value(text)
  }

  render() {
    return (
      <TranslationContext.Provider value={this.props.value}>
        {this.props.children}
      </TranslationContext.Provider>
    )
  }
}

export function withTranslations(Component) {
  return function TranslatedComponent(props) {
    return (
      <TranslationContext.Consumer>
        {t => <Component {...props} t={t} />}
      </TranslationContext.Consumer>
    )
  }
}

export default TranslationProvider
