import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import powerform, * as fromPowerform from './powerform'
import messages from './messages'
import loading from './loading'
import context from './context'
import locale from './locale'

const rootReducer = combineReducers({
  loading,
  powerform,
  messages,
  context,
  routing: routerReducer,
  locale,
})

export default rootReducer

export const getErrorCode = state =>
  fromPowerform.getErrorCode(state.powerform)
