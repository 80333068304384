import { CONTEXT_SET } from '../actions'

export default function context(state = {}, action) {
  switch (action.type) {
    case CONTEXT_SET:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const getPowerFormId = state =>
  state.params && state.params.powerformId

export const getServer = state =>
  state.params && state.params.server

export const getQuery = state =>
  state.query && state.query

export const getHdnStartValues = state =>
  state.hdnStartValues

export const getHdnPersistOriginalValues = state =>
  state.hdnPersistOriginalValues

export const getApiErrors = state => state.apiError;

export const getHasAlreadyPosted = state => state.hasAlreadyPosted;
