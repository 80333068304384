import React from 'react'
import { Icon } from '@ds/ui'
import { connect } from 'react-redux'
import { withTranslations } from '../lib/TranslationProvider'
import { powerformErrorCodes } from '../lib/constants'
import { getErrorCode } from '../reducers'

const getErrorValues = (t, errorCode, errorMessage) => {
  if (errorMessage) {
    return {
      errorHeader: errorMessage,
      errorBody: errorMessage
    }
  }
  const lowerCaseErrorCode = errorCode.toLowerCase()

  switch (lowerCaseErrorCode) {
    case powerformErrorCodes.INACTIVE:
      return {
        errorHeader: t('error.powerform_inactive_header'),
        errorBody: t('error.powerform_inactive_body'),
      }
    case powerformErrorCodes.IRRETRIEVABLE_RECIPIENT:
      return {
        errorHeader: t('error.powerform_irretrievable_recipient_header'),
        errorBody: t('error.powerform_irretrievable_recipient_body'),
      }
    case powerformErrorCodes.NOT_FOUND:
      return {
        errorHeader: t('error.powerform_not_found_header'),
        errorBody: t('error.powerform_not_found_body'),
      }
    case powerformErrorCodes.NO_FREEFORM:
      return {
        errorHeader: t('error.powerform_freeform_signing_not_allowed'),
        errorBody: t('error.powerform_freeform_signing_not_allowed'),
      }
    case powerformErrorCodes.TOO_MANY_REQUESTS:
      return {
        errorHeader: t('error.powerform_too_many_requests'),
        errorBody: t('error.powerform_too_many_requests'),
      }
    default:
      return {
        errorHeader: t('error.powerform_default_header'),
        errorBody: t('error.powerform_default_body'),
      }
  }
}
const PowerformError = ({
  errorHeader,
  errorBody,
}) => (
  <div className="emptyState">
    <Icon kind="alert" color="persianRed" />
    { errorHeader &&
      <h2 className="emptyState_text">
        { errorHeader }
      </h2>
    }
    { errorBody &&
      <p className="emptyState_text u-text-black">
        { errorBody }
      </p>
    }
  </div>
)

const connectedPowerformError = connect(
  state => ({
    errorCode: getErrorCode(state),
  }),
  null,
  (stateProps, dispatchProps, ownProps) => ({
    ...getErrorValues(ownProps.t, stateProps.errorCode, stateProps.errorMessage),
  })
)(PowerformError)

export default withTranslations(connectedPowerformError)
