import "core-js"
import "es6-promise/auto"
import React from 'react'
import { render } from 'react-dom'
import _ from 'lodash'
import Application from './components/App'
import './powerforms-content.css'
import * as api from './lib/api'

render(<Application />, document.querySelector('#root'))

const format = (message, auxData) => {
  if (auxData &&
    (
      _.isPlainObject(auxData) ||
      _.isString(auxData)
    )
  ) {
    return { message, details: _.identity(auxData) }
  }
  return null
}

const getMessage = (obj) => {
  if (_.isString(obj)) return obj
  if (_.isError(obj)) return _.get(obj, 'message')

  return ''
}
// eslint-disable-next-line no-console
const errorLog = console.error

/**
* Overrides console.error to log handled errors.
* All handled errors from this app needs to use one or two args to handle the error.
* If any library is trying to log errors with more number of args, log only first two
*   and error in the console as is.
*  @param {string|Error} args[0]
*  @param {string|Object} args[1]
* */
// eslint-disable-next-line no-console
console.error = (...args) => {
  if (args.length === 0) return

  // if error, load message otherwise, log the message as is
  const message = getMessage(args[0])

  // ensure data logged is an object
  let data = format(message, args.length > 1 && args[1]) ||
    (message ? { message } : {})

  // If we have a stack trace, add it
  if (_.has(args[0], 'stack')) {
    data = {
      ...data,
      stackTrace: _.get(args[0], 'stack'),
    }
  }

  const baseLog = api.baseLogData()
  // log errors to kazmon
  api.logToKazmon(JSON.stringify({
    error: data,
    ...baseLog,
  }))

  if (errorLog) {
    errorLog.apply(this, [...args])
  }
}

window.onerror = (message, file, line, column, error) => {
  // we are interested only in the unhandled errors
  // extract error with stack trace from the args and then pass rest as another obj
  const details = {
    message, file, line, column,
  }
  // eslint-disable-next-line no-console
  console.error(error, details)
}
