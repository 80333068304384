import _ from 'lodash'

const camelize = str => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase())).replace(/\s+/g, '')

export const validateEmail = ({ email, isRequired }) => {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (email) {
    return email.match(re) ? null : 'validate.email_invalid'
  }
  return isRequired ? 'validate.email_required' : null
}

export const validateName = (nameData) => {
  if (nameData) {
    const { name, isRequired, isConditional } = nameData
    const isValid = str => str.length >= 1 && str.length < 200
    if (name && !isConditional) {
      return isValid(name) ? null : 'validate.name_invalid'
    }
    if (isConditional) {
      return null
    }
    return isRequired ? 'validate.name_required' : null
  }
  return false // ASSUMPTION: undefined is invalid name data
}

export const validateRecipients = (recipients, validateBlankFields = false) => {
  let lowerCaseRecipients = _.cloneDeep(recipients)

  // Recipient data is passed in both Upper Camel Case and Lower Camel Case -
  // to avoid duplicating validation methods, the keys are being lower camel cased
  lowerCaseRecipients = _.map(lowerCaseRecipients, recipient =>
    _.mapKeys(recipient, (v, k) => camelize(k)))

  return lowerCaseRecipients.reduce((acc, recipient) => {
    const blankName = recipient.name === ''
    const blankEmail = recipient.email === ''

    const error = {
      name: validateBlankFields && blankName ? null : validateName(recipient),
      email: recipient.isConditional || (validateBlankFields && blankEmail) ? null : validateEmail(recipient),
    }

    return (error.name || error.email) ? [...acc, { ...error, role: recipient.role }] : acc
  }, [])
}
